
import './App.css';
import ResumePartOne from './components/ResumePartOne';
import ResumePartTwo from './components/ResumePartTwo';
import ResumePartThree from './components/ResumePartThree';
function App() {
  return (
    <div >
      <ResumePartOne/>
      <ResumePartTwo/> 
      {/* <ResumePartOne/> */}
      <ResumePartThree/>
    </div>
  );
}

export default App;
