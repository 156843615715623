import '../components/ResumePartThreeStyles.css'
import shape1 from '../assests/shape1.png'
import shape2 from '../assests/shap2.png'
import shape3 from '../assests/shape3.png'


// font-awsome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircle} from '@fortawesome/free-solid-svg-icons'

const ResumePartThree = () => {
  return (
    <div className="container">

    <div className="row">
     
        <div className="col-md-5">
            

     
            <div className="technical-skills-section">
                <div className="technical">
                    <img src={shape1} id="top-image" alt=''/>
                    <h2 className="techskils">Languages</h2>

                    <p className="tech">English</p>
                    <div className="marks-dots">
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                    </div>

                    <p className="tech">Hindi</p>
                    <div className="marks-dots">
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                    </div>

                    <p className="tech">French</p>
                    <div className="marks-dots">
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} /><i className="fa-solid fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                        <div className="dots"><FontAwesomeIcon icon={faCircle} className='greyCircle' /><i className="fa-regular fa-circle"></i></div>
                    </div>
                    

                    <h2 id="awards">Internships</h2>
                    <h4 className="award setTop">Internship 1</h4>
                    <p className='setTop'>Jan 2019 - Jun 2019</p>

                    <p className="company">Company 1 | City | Country</p>
                    <p>URL : www.example1.com</p>

                    <ul>
                        <li> Lorem mpsum dolor smt amet sed doadmpmscmng.</li>
                        <li>  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor </li>
                    </ul>

                    
                    <h4 className="personal">Personal Interest</h4>
                
                    <ul>
                        <li> Excepteur sjnt ecatpjdatat.</li>
                        <li> Excepteur sjnt ecatpjdatat.</li>
                        <li> Excepteur sjnt ecatpjdatat.</li>
                        <li> Excepteur sjnt ecatpjdatat.</li>
                        <li> Excepteur sjnt ecatpjdatat.</li>
                        <li> Excepteur sjnt ecatpjdatat.</li>
                    </ul>

                
                    <img src={shape2} id="image-shap2" alt=''/>
                </div>
            </div>
   

        


        </div>
       
        <div className="col-md-7">


            <div className="Work-experience">
                <div className="experience">
                    <h2 style={{"font-weight": "bold"}}>Projects</h2>
                    
                    <h4>Project Title</h4>
                    <p className='setTop'>Jan 2022 - Ongoing</p>

                    <h4 style={{"font-weight": "bold"}}>Client name</h4>
                    <p>
                        Lorem mpsum dolor smt amet, consectetur admpmscmng
                        elmt, sed do emusmod tempor mncmdmdunt ut labore et
                        dolore magna almqua. Ut enmm ad mmnmm venmam, qums
                        nostrud exercmtatmon ullamco laborms nmsm ut almqump ex
                        ea commodo.Lorem mpsudolor smt amet, consectetur
                        admpmscmng elmt, sed do emusmod tempor mncmdmdunt ut
                        labore et dolore magna almqua. Ut enmm ad mmnmm
                        venmam, qums nostrud exercmtatmon ullamco laborms nmsm
                        ut almqump ex ea commodo venmam, qums nostrud
                        exercmtatmon.
                    </p>
                    <hr/>

                    <h4 id="junior">Project Title</h4>
                    <p className='setTop'>Jan 2020 - Jan 2021</p>

                    <h4 style={{"font-weight": "bold"}}>Client name</h4>
                    <p>
                        Lorem mpsum dolor smt amet, consectetur admpmscmng
                        elmt, sed do emusmod tempor mncmdmdunt ut labore et
                        dolore magna almqua. Ut enmm ad mmnmm venmam, qums
                        nostrud exercmtatmon ullamco laborms nmsm ut almqump ex
                        ea commodo.Lorem mpsudolor smt amet, consectetur
                        admpmscmng elmt, sed do emusmod tempor mncmdmdunt ut
                        labore et dolore magna almqua. Ut enmm ad mmnmm
                        venmam, qums nostrud exercmtatmon ullamco laborms nmsm
                        ut almqump ex ea commodo venmam, qums nostrud
                        exercmtatmon.
                    </p>
                    <hr/>
                    
                    <h2>Traning</h2>
                    <h4 id="graphic">Traning Title</h4>
                    <p className='setTop'>Jan 2018 - Jun 2018</p>

                    <h4>Institution</h4>
                    <p>
                        Lorem mpsum dolor smt amet, consectetur admpmscmng
                        elmt, sed do emusmod tempor mncmdmdunt ut labore et
                        dolore magna almqua. Ut enmm ad mmnmm venmam, qums
                        nostrud exercmtatmon ullamco laborms nmsm ut almqump ex
                        ea commodo.Lorem mpsudolor smt amet, consectetur
                        admpmscmng elmt, sed do emusmod tempor mncmdmdunt ut
                        labore et dolore magna almqua. Ut enmm ad mmnmm
                        venmam, qums nostrud exercmtatmon ullamco laborms nmsm
                        ut almqump ex ea commodo venmam, qums nostrud
                        exercmtatmon.
                    </p>
                    
                </div>
            </div>
        
        </div>
   
    </div>
   
    <img src={shape3} id="bottom-image" alt=''/>
</div>
  )
}

export default ResumePartThree;
